import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Button, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "30px 0 80px 0",
	"sm-padding": "40px 0",
	"background": "--color-greyD1"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--lead",
			"margin": "20px 0 20px 0",
			"text-align": "center",
			"color": "--light",
			"children": <>
				Якщо у вас є ще питання, будь ласка, звертайтеся до нас, і ми з радістю надамо вам відповіді та консультацію.{"\n\n"}
			</>
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"font": "--lead",
			"margin": "20px",
			"type": "link",
			"href": "/Contacts",
			"text-decoration-line": "initial"
		}
	}
};

const Contact = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" align-items="center" />
		<Text {...override("text")} />
		<Button {...override("button")}>
			Контакти
		</Button>
		{children}
	</Section>;
};

Object.assign(Contact, { ...Section,
	defaultProps,
	overrides
});
export default Contact;