import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Icon, Strong, Box, List, Image, Section } from "@quarkly/widgets";
import { MdDone } from "react-icons/md";
const defaultProps = {
	"padding": "80px 0",
	"sm-padding": "40px 0",
	"min-height": "600px",
	"sm-min-height": "auto",
	"background": "--color-greyD1",
	"display": "flex",
	"quarkly-title": "Advantages/Features-28"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "45%",
			"flex-direction": "column",
			"justify-content": "flex-start",
			"align-items": "flex-start",
			"lg-width": "80%",
			"lg-align-items": "flex-start",
			"lg-margin": "0px 0px 60px 0px",
			"sm-margin": "0px 0px 40px 0px",
			"sm-padding": "0px 0px 0px 0px",
			"padding": "24px 0px 16px 0px",
			"lg-flex-direction": "row",
			"lg-flex-wrap": "wrap",
			"sm-width": "100%"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"margin": "0px",
			"font": "normal 600 40px/1.5 --fontFamily-sans",
			"color": "--dark",
			"width": "100%",
			"md-width": "100%",
			"md-margin": "0px 0px 32px 0px",
			"md-padding": "0px 0px 0px 0px",
			"sm-font": "normal 600 40px/1.2 --fontFamily-sans",
			"children": "Наші переваги:"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"as": "p",
			"margin": "20px 0 0 0",
			"font": "--lead",
			"font-size": "20px",
			"font-weight": "300",
			"color": "--darkL2",
			"width": "100%",
			"md-width": "100%",
			"md-margin": "0px 0px 0px 0px",
			"md-padding": "0px 0px 0px 0px",
			"children": "Обирайте нас для надійного та професійного обслуговування вашого автомобіля!"
		}
	},
	"list": {
		"kind": "List",
		"props": {
			"margin": "40px 0px 0px 0px",
			"padding": "0px 0px 0px 0px",
			"as": "ul",
			"sm-margin": "24px 0 0 0",
			"list-style-type": "none",
			"font": "normal normal 18px/150% sans-serif",
			"display": "flex",
			"flex-direction": "column",
			"grid-gap": "25px",
			"lg-margin": "32px 0px 0px 0px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 0px 0px",
			"display": "flex",
			"grid-gap": "16px"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"display": "block",
			"category": "md",
			"icon": MdDone,
			"size": "20px",
			"color": "--light",
			"margin": "5px 0 0 0"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"padding": "0",
			"margin": "0",
			"color": "--dark",
			"font": "18px/27px --fontFamily-sans",
			"children": <>
				<Strong>
					Досвід і Професіоналізм:{" "}
				</Strong>
				Наша команда складається з досвідчених фахівців, які володіють високим рівнем професійної компетентності.
			</>
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 0px 0px",
			"display": "flex",
			"grid-gap": "16px"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"display": "block",
			"category": "md",
			"icon": MdDone,
			"size": "20px",
			"color": "--light",
			"margin": "5px 0 0 0"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"padding": "0",
			"margin": "0",
			"color": "--dark",
			"font": "18px/27px --fontFamily-sans",
			"children": <>
				<Strong>
					Швидкість та Ефективність
				</Strong>
				: Ми працюємо швидко та ефективно, щоб ви могли повернутися на дорогу якнайшвидше.
			</>
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 0px 0px",
			"display": "flex",
			"grid-gap": "16px"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"display": "block",
			"category": "md",
			"icon": MdDone,
			"size": "20px",
			"color": "--light",
			"margin": "5px 0 0 0"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"padding": "0",
			"margin": "0",
			"color": "--dark",
			"font": "18px/27px --fontFamily-sans",
			"children": <>
				<Strong>
					Індивідуальний Підхід
				</Strong>
				: Ми завжди слухаємо ваші потреби та надаємо індивідуальний підхід до кожного клієнта.
			</>
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 0px 0px",
			"display": "flex",
			"grid-gap": "16px"
		}
	},
	"icon3": {
		"kind": "Icon",
		"props": {
			"display": "block",
			"category": "md",
			"icon": MdDone,
			"size": "20px",
			"color": "--light",
			"margin": "5px 0 0 0"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"padding": "0",
			"margin": "0",
			"color": "--dark",
			"font": "18px/27px --fontFamily-sans",
			"children": <>
				<Strong>
					Зберігання Гуми
				</Strong>
				: Ми зберігаємо другий комплект гуми чи набір дисків з гумою на нашому сучасному складі для вашої зручності.
			</>
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 0px 0px",
			"display": "flex",
			"grid-gap": "16px"
		}
	},
	"icon4": {
		"kind": "Icon",
		"props": {
			"display": "block",
			"category": "md",
			"icon": MdDone,
			"size": "20px",
			"color": "--light",
			"margin": "5px 0 0 0"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"padding": "0",
			"margin": "0",
			"color": "--dark",
			"font": "18px/27px --fontFamily-sans",
			"children": <>
				<Strong>
					Підтримка Увесь Рік
				</Strong>
				: Наша команда готова надавати вам послуги протягом усього року, включаючи сезони перевзування гуми.
			</>
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "50%",
			"justify-content": "flex-end",
			"lg-justify-content": "center",
			"overflow-y": "hidden",
			"overflow-x": "hidden",
			"lg-width": "100%",
			"padding": "0px 0px 0px 16px",
			"align-items": "center",
			"lg-padding": "0px 0px 0px 0px",
			"font": "20px sans-serif"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://uatrestinlive.net/images/a1.jpg",
			"display": "block",
			"border-radius": "32px"
		}
	}
};

const Advantages = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			flex-direction="row"
			flex-wrap="wrap"
			grid-gap="5%"
			sm-min-width="280px"
		/>
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
			<List {...override("list")}>
				<Box {...override("box1")}>
					<Icon {...override("icon")} />
					<Text {...override("text2")} />
				</Box>
				<Box {...override("box2")}>
					<Icon {...override("icon1")} />
					<Text {...override("text3")} />
				</Box>
				<Box {...override("box3")}>
					<Icon {...override("icon2")} />
					<Text {...override("text4")} />
				</Box>
				<Box {...override("box4")}>
					<Icon {...override("icon3")} />
					<Text {...override("text5")} />
				</Box>
				<Box {...override("box5")}>
					<Icon {...override("icon4")} />
					<Text {...override("text6")} />
				</Box>
			</List>
		</Box>
		<Box {...override("box6")}>
			<Image {...override("image")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Advantages, { ...Section,
	defaultProps,
	overrides
});
export default Advantages;